import { gql } from '@apollo/client'
import { signOut, useSession } from 'next-auth/react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { getItem, removeItem, setItem, ValueType } from '../use-storage'
import { User, UserType } from './index'
import { useUserInfoQuery } from '@/gql'

export type CreateProps = { emailAddress: UserType['emailAddress'] }
export type UpdateUserProps = {
  firstName: UserType['firstName']
  lastName: UserType['lastName']
  biologicalSex: UserType['biologicalSex']
  phoneNumber: UserType['phoneNumber']
  deliveryAddress: UserType['deliveryAddress']
  newEmailAddress?: UserType['emailAddress'] | undefined
  emailAddressConfirm?: UserType['emailAddress'] | undefined
  newPassword?: string | undefined
  confirmPassword?: string | undefined
}

export type UseUserResult = {
  create({ emailAddress }: CreateProps): void
  clear(): void
  currentUser(): User | undefined
  logout: () => Promise<void>
  isLoggedIn: boolean
  isReady: boolean
  user: User | undefined
}

const createSessionStorage = (key: string, cls?: any) => {
  return {
    get<Value extends ValueType = ValueType>(): Value | undefined {
      const sessionData = getItem<Value>(key, 'session')
      if (!sessionData) return undefined
      return cls ? new cls(sessionData) : sessionData
    },
    set(value: any) {
      if (value === undefined) {
        removeItem(key, 'session')
      } else {
        setItem(key, value, 'session')
      }
    },
  }
}

const guestUserStorage = createSessionStorage('guestUser')

export type CurrentUserContextType = UseUserResult
export const CurrentUserContext = createContext<CurrentUserContextType | undefined>(undefined)
export const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: userData, loading, refetch } = useUserInfoQuery({ fetchPolicy: 'no-cache' })
  const userInfo = userData?.viewer?.user
  const { data, status } = useSession()
  const client = useApolloClient()
  const serializedGuest = guestUserStorage.get()
  const [guest, setGuest] = useState<User | undefined>(serializedGuest ? new User(serializedGuest) : undefined)

  useEffect(() => {
    if (typeof window !== 'undefined' && status === 'authenticated') {
      data?.idToken && window.localStorage.setItem('idToken', data?.idToken)
      data?.accessToken && window.localStorage.setItem('accessToken', data?.accessToken)
    }

    if (typeof window !== 'undefined' && status === 'unauthenticated') {
      window.localStorage.removeItem('idToken')
      window.localStorage.removeItem('accessToken')
    }
  }, [data?.idToken, data?.accessToken, status])

  const user = useMemo(() => {
    refetch()
    if (data?.user) {
      guestUserStorage.set(undefined)
      return new User({
        emailAddress: data.user.email,
        authenticated: true,
        isAdmin: data.user.isAdmin,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        friendlyName: userInfo?.friendlyName,
        phoneNumber: userInfo?.phoneNumber,
      })
    }
    if (guest) {
      return new User(guest)
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, guest, userInfo])

  const createGuest = useCallback(({ emailAddress }: CreateProps) => {
    const guest = new User({ emailAddress, newUser: true })
    guestUserStorage.set(guest)
    setGuest(guest)
  }, [])

  const clearGuest = useCallback(() => {
    guestUserStorage.set(undefined)
    setGuest(undefined)
  }, [])

  const currentUser = (): User | undefined => {
    return user
  }

  const logout = async () => {
    client.stop()
    await client.resetStore()
    await signOut({ redirect: false })
    window.sessionStorage.removeItem('checkout')
    window.sessionStorage.removeItem('idToken')
    window.sessionStorage.removeItem('accessToken')
  }

  const value = {
    create: createGuest,
    clear: clearGuest,
    isLoggedIn: user?.isLoggedIn || false,
    user,
    isReady: status !== 'loading' && !loading,
    logout,
    currentUser,
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

export const useUser = () => {
  const context = useContext(CurrentUserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a CurrentUserProvider')
  }
  return context
}

export default useUser

gql`
  query UserInfo {
    viewer {
      user {
        email
        firstName
        lastName
        friendlyName
        phoneNumber
      }
    }
  }
`
