import { Field, useField, useFormikContext } from 'formik'
import { Fragment, useEffect, useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type TextPropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type FormikPasswordProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & TextPropsCommon

export const FormikPassword = ({
  name,
  onKeyUp,
  errorMessage,
  boxSize,
  placeholder,
  depends,
  setIsHidden,
  ...props
}: FormikPasswordProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name })

  const [passwordVisible, setPasswordVisible] = useState(false)

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className, attributes } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Text',
    className: `${props.className} ${
      props?.readOnly === 'readOnly'
        ? 'focus:ring-gray-500 focus:border-gray-500 border-gray-300-500 text-gray-900 bg-gray-300'
        : errorMessage
        ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
        : meta.touched &&
          'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300'
    } focus:ring-selphBlue-300 focus:border-selphBlue-300`,
  })

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  const iconColor =
    props?.readOnly === 'readOnly'
      ? 'text-gray-500'
      : errorMessage
      ? 'text-red-500'
      : meta.touched
      ? 'text-green-500'
      : 'text-gray-500'

  const SvgIcon = passwordVisible ? EyeSlashIcon : EyeIcon

  return (
    <Fragment>
      <div className={`relative rounded-md shadow-sm ${className}`}>
        <Field
          name={name}
          type={passwordVisible ? 'text' : 'password'}
          onKeyUp={keyup}
          placeholder={placeholder}
          {...attributes}
          {...props}
          className={`${className} pr-12`}
        />

        <button
          type="button"
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="absolute inset-y-0 right-0 md:pl-18 flex items-center"
        >
          <div className="inset-y-0 right-0 px-3 flex items-center text-sm text-gray-500  hover:text-blue-500">
            <SvgIcon className={`h-6 w-6 ${iconColor}`} />
          </div>
        </button>
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </Fragment>
  )
}

FormikPassword.displayName = 'Form.Text.FormikPassword'

export default FormikPassword
