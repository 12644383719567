import { SVGProps } from 'react'
import { useState } from 'react'
import ReactGA from 'react-ga4'
import FooterEmailSignUp from './ui/form/footer-email-signup'
import * as UI from '@/ui'

const social = [
  {
    name: 'Facebook',
    to: 'facebook',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    to: 'instagram',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'TikTok',
    to: 'tiktok',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
        />
      </svg>
    ),
  },
]

export const Footer = () => {
  const [signUpResult, setSignUpResult] = useState('')
  const [showSignUpModal, SetShowSignUpModal] = useState(false)
  return (
    <div className="relative" id="footer">
      <div className="absolute left-0 right-0 top-[17%] md:top-[26.5%] lg:top-[45%] mx-auto px-2 sm:px-4 md:px-6 py-3 lg:py-0 w-full max-w-md md:max-w-full">
        <UI.Grid
          gap="large"
          size={{ default: 1, md: 2, lg: 7 }}
          colVerticalAlign={{ default: 'bottom', md: 'top', lg: 'bottom' }}
          className="max-w-7xl mx-auto"
        >
          <UI.GridSpan size={{ default: 1, lg: 3 }} className="md:order-2">
            <div className="max-w-md md:ml-auto mx-auto ">
              <p className="text-sm mb-1 text-selphGrey-100 opacity-70 font-bold uppercase">
                Get updates on how to live more healthy years
              </p>
              <UI.Form.Form
                initialValues={{ emailAddress: '' }}
                validationSchema={UI.Form.validation.schema({
                  emailAddress: { label: 'This', type: 'string', required: true, criteria: 'emailAddress' },
                })}
                onSubmit={async (values, actions) => {
                  const res = await fetch('/api/mailchimp-user-subscribe', {
                    body: JSON.stringify({
                      email: values.emailAddress,
                    }),
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    method: 'POST',
                  })
                  if (!res.ok) {
                    setSignUpResult('Error')
                    SetShowSignUpModal(true)
                    console.error('Newsletter sign-up error')
                  }
                  ReactGA.event({
                    category: 'subscribe',
                    action: 'newsletter',
                    label: 'footer',
                  })
                  setSignUpResult('Success')
                  SetShowSignUpModal(true)
                  actions.resetForm()
                }}
              >
                <FooterEmailSignUp
                  name="emailAddress"
                  placeholder="email address"
                  className="flex-shrink w-full bg-transparent text-selphGrey-100 placeholder:text-sm placeholder:text-selphGrey-100 border-selphGrey-100 focus:outline-none focus:ring-1 focus:ring-hotPink-500 focus:border-hotPink-500 h-12"
                />
              </UI.Form.Form>
              <UI.Modal show={showSignUpModal} onClose={() => SetShowSignUpModal(!showSignUpModal)}>
                <UI.Block gap="xs">
                  {signUpResult === 'Success' ? (
                    <>
                      <UI.Heading size={{ default: 'xxs', sm: 'xs' }}>Thank you!</UI.Heading>
                      <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>
                        You&apos;ve joined the Selph community and will get email updates from us.
                      </UI.Paragraph>
                    </>
                  ) : (
                    <>
                      <UI.Heading size={{ default: 'xxs', sm: 'xs' }}>Something went wrong</UI.Heading>
                      <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>
                        Sorry, there was a problem signing you up. Please try again.
                      </UI.Paragraph>
                    </>
                  )}
                </UI.Block>
              </UI.Modal>
            </div>
          </UI.GridSpan>

          <UI.GridSpan size={{ default: 1, lg: 2 }} className="md:order-1">
            <div className="flex sm:gap-x-10 max-md:justify-between lg:items-end text-sm">
              <UI.Block display="flexCol" gap={{ default: 'xs', md: 'xs' }}>
                <span className=" text-selphGrey-100 opacity-70 font-bold text-sm mb-1">HELP</span>
                <UI.Link color="footer" to="team" className="link-hover-pink">
                  About Us
                </UI.Link>
                <UI.Link color="footer" to="contact" className="link-hover-pink">
                  Contact Us
                </UI.Link>
                <UI.Link color="footer" to="help" className="link-hover-pink">
                  Get Help
                </UI.Link>
                <UI.Link color="footer" to="terms-of-service" className="link-hover-pink">
                  Terms of Service
                </UI.Link>
                <UI.Link color="footer" to="privacy" className="link-hover-pink">
                  Privacy Policy
                </UI.Link>
                <UI.Link color="footer" to="cookie" className="link-hover-pink">
                  Cookie Policy
                </UI.Link>
              </UI.Block>

              <UI.Block display="flexCol" gap={{ default: 'xs', md: 'xs' }}>
                <span className=" text-selphGrey-100 opacity-70 font-bold text-sm mb-1">QUICK LINKS</span>
                <UI.Link color="footer" to="tests" className="link-hover-pink">
                  Health Tests
                </UI.Link>
                <UI.Link color="footer" to="learn" className="link-hover-pink">
                  Learn
                </UI.Link>
                <UI.Link color="footer" to="offers" className="link-hover-pink">
                  Offers
                </UI.Link>
                <UI.Link color="footer" to="team" className="link-hover-pink">
                  The Team
                </UI.Link>
              </UI.Block>

              <UI.Block display="flexCol" gap={{ default: 'xs', md: 'xs' }}>
                <span className=" text-selphGrey-100 opacity-70 font-bold text-sm mb-1">FOLLOW</span>
                {social.map((item) => (
                  <UI.Link key={item.name} to={item.to} color="footer" className="link-hover-pink">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6 ml-auto sm:mx-auto" aria-hidden="true" />
                  </UI.Link>
                ))}
              </UI.Block>
            </div>
          </UI.GridSpan>

          <UI.GridSpan size={{ default: 1, md: 2, lg: 2 }} className="md:order-3">
            <UI.Block
              gap="xs"
              className="text-selphGrey-100 text-sm  max-w-fit mx-auto lg:ml-auto text-center lg:text-right "
            >
              <p>Selph Ltd, Reg. No. 12169438</p>
              <p>124 City Road, London, EC1V 2NX</p>
              <UI.Image
                src="/images/stripeBadge_white.svg"
                width={120}
                height={27}
                alt="Stripe Badge"
                className=" max-lg:mr-auto ml-auto"
              />
            </UI.Block>
          </UI.GridSpan>
        </UI.Grid>
      </div>

      <svg
        width="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        className="h-[550px] md:h-[500px] lg:h-[450px]"
      >
        <defs>
          <linearGradient id="footer-grad">
            <stop offset="0" stopColor="#223240" />
            <stop offset="0.2" stopColor="#223240" />
            <stop offset="0.98" stopColor="#2b3d5b" />
            <stop offset="1" stopColor="#223240" />
          </linearGradient>

          <radialGradient id="footer-grad2" cx="0.55" cy="-0.1" fy="-0.2" r="1.3">
            <stop offset="0" stopColor="#223240" stopOpacity="1" />
            <stop offset="0.2" stopColor="#223240" stopOpacity="1" />
            <stop offset="0.5" stopColor="transparent" stopOpacity="0" />
          </radialGradient>

          <linearGradient id="border-grad">
            <stop offset="0" stopColor="#d4dbe4" stopOpacity="0" />
            <stop offset="0.25" stopColor="white" stopOpacity="1" />
            <stop offset="0.8" stopColor="white" stopOpacity="1" />
            <stop offset="0.95" stopColor="#d4dbe4" stopOpacity="0" />
          </linearGradient>

          <filter id="shadow" x="0" y="0.1" width="150%">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="4" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.5" />
            </feComponentTransfer>
          </filter>

          <filter id="shadow2" x="0" y="0.1">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="0.8" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.5" />
            </feComponentTransfer>
          </filter>
        </defs>

        <g strokeWidth="0" stroke="#000" fill="url(#footer-grad)">
          <path d="M0,100 L0,0 Q50,100 100,0 L100,100" className="hidden lg:block" />
          <path d="M0,100 L0,0 Q50,45 100,0 L100,100" className="hidden md:block lg:hidden" />
          <path d="M0,100 L0,0 Q50,25 100,0 L100,100" className="md:hidden" />
        </g>

        <g strokeWidth="0" stroke="#000" fill="url(#footer-grad2)">
          <path d="M0,100 L0,0 Q50,100 100,0 L100,100" className="hidden lg:block" />
          <path d="M0,100 L0,0 Q50,45 100,0 L100,100" className="hidden md:block lg:hidden" />
        </g>

        <g strokeWidth="2" stroke="url(#border-grad)" fill="transparent">
          <path d="M0,-3 Q50,102 102,-6" className="hidden lg:block" />
          <path d="M0,-2 Q50,45 102,-3" className="hidden md:block lg:hidden" />
        </g>

        <g strokeWidth="2" stroke="white" fill="transparent" filter="url(#shadow)">
          <path d="M0,0 Q50,98 102,-4" className="hidden lg:block" />
          <path d="M0,-2 Q50,45 102,-3" className="hidden md:block lg:hidden" />
        </g>

        <g strokeWidth="1" stroke="url(#border-grad)" fill="transparent">
          <path d="M-2,-2 Q50,27 102,-2" className="md:hidden" />
        </g>

        <g strokeWidth="1" stroke="white" fill="transparent" filter="url(#shadow2)">
          <path d="M-2,-2 Q50,27 102,-2" className="md:hidden" />
        </g>
      </svg>
    </div>
  )
}

export default Footer
