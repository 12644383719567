import { useNotification } from '../ui/use-notification'
import { Authenticated } from '../user'
import NavigationMenu from './navigation-menu'
import * as UI from '@/ui'

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const { content } = useNotification()

  return (
    <>
      <Authenticated group="admin">
        <NavigationMenu />

        <UI.Page containerStyle="slim">
          {content && <UI.Notification {...content} className="mb-5" />}
          {children}
        </UI.Page>
      </Authenticated>
    </>
  )
}

export default AdminLayout
