import { useUi } from '../../use-ui'

import { type TextPropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type InputTextProps = {
  initialValue?: string | number | undefined
} & TextPropsCommon

export const InputText = ({
  name,
  field = 'text',
  boxSize,
  errorMessage,
  placeholder,
  initialValue,
  readOnly,
  ...props
}: InputTextProps) => {
  const { className, attributes } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Text',
    className: `${props.className} ${
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    } focus:ring-selphBlue-300 focus:border-selphBlue-300`,
  })

  return (
    <>
      <input
        type={field}
        name={name}
        readOnly={readOnly === 'readOnly'}
        defaultValue={initialValue}
        placeholder={placeholder}
        {...props}
        {...attributes}
        className={className}
      />
      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

InputText.displayName = 'Form.Text.InputText'

export default InputText
