import { useUi, type Breakpoints } from './use-ui'

export type BlockGaps = 'none' | 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
export type BlockDisplay = 'block' | 'flexCol'

export const gaps = {
  default: '',
  none: 'space-y-0', // Tailwind: sm:space-y-0  md:space-y-0  lg:space-y-0  xl:space-y-0
  xs: `space-y-1`, // Tailwind: sm:space-y-1  md:space-y-1  lg:space-y-1  xl:space-y-1
  small: `space-y-2`, // Tailwind: sm:space-y-2  md:space-y-2  lg:space-y-2  xl:space-y-2
  medium: `space-y-5`, // Tailwind: sm:space-y-5  md:space-y-5  lg:space-y-5  xl:space-y-5
  large: `space-y-6`, // Tailwind: sm:space-y-6  md:space-y-6  lg:space-y-6  xl:space-y-6
  xl: `space-y-10`, // Tailwind: sm:space-y-10  md:space-y-10  lg:space-y-10  xl:space-y-10
  xxl: `space-y-16`, // Tailwind: sm:space-y-16  md:space-y-16  lg:space-y-16  xl:space-y-16
}

export const displays = {
  default: '',
  block: '',
  flexCol: 'flex flex-col',
}

export type BlockProps = {
  gap?: Breakpoints<BlockGaps>
  display?: Breakpoints<BlockDisplay>
  className?: string
  children: React.ReactNode
  id?: string
}

export const Block = ({ gap = 'medium', className, display = 'block', children, id }: BlockProps) => {
  const ui = useUi({
    styles: {
      gap: { options: gaps, selected: gap },
      display: { options: displays, selected: display },
    },
    className,
    name: 'Block',
  })

  return (
    <div {...ui.attributes} className={ui.className} id={id}>
      {children}
    </div>
  )
}

Block.displayName = 'Block'

export default Block
