import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { getHref } from '../../routes'
import { useUser, UseUserResult } from './use-user'

export type AuthenticatedGroups = 'customer' | 'admin'

export type AuthenticatedProps = {
  children: React.ReactNode | ((user: UseUserResult) => React.ReactNode)
  group: AuthenticatedGroups
}

export const Authenticated = ({ children, group }: AuthenticatedProps) => {
  const userData = useUser()
  const { isReady: isUserReady, isLoggedIn, user } = userData

  const { replace: redirect } = useRouter()
  const [isReady, setIsReady] = useState<boolean>(false)

  const isAuthorised = {
    customer: user?.isCustomer || user?.isAdministrator,
    admin: user?.isAdministrator,
  }[group]

  const canView = isUserReady && isLoggedIn && isAuthorised

  useEffect(() => {
    if (isUserReady) {
      if (!isLoggedIn) {
        redirect(getHref(['loginWith', { redirect: window.location.href }]))
        return
      }

      if (!isAuthorised) {
        redirect(getHref('account'))
        return
      }

      setIsReady(true)
    }
  }, [isUserReady, isLoggedIn, isAuthorised, redirect])

  if (!isReady && !user) {
    return <p>Loading...</p>
  }

  if (!canView) return null

  children = typeof children === 'function' ? children(userData) : children

  return <>{children}</>
}

export default Authenticated
