import { useUi } from './use-ui'

export type ListStyles = 'disc' | 'number' | 'none'
export type ListGaps = 'small' | 'medium' | 'large' | 'xl'
export type ListMargin = 'none' | 'small' | 'medium' | 'large'

export type ListProps = ListPropsBullet | ListPropsNumber

export type ListPropsShared = {
  gap?: ListGaps
  margin?: ListMargin
}

export type ListPropsBullet = { listStyle?: ListStyles } & ListPropsShared &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>

export type ListPropsNumber = {
  listStyle?: 'number'
} & ListPropsShared &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLOListElement>, HTMLOListElement>

export const List = ({ gap = 'medium', listStyle = 'disc', children, margin = 'medium', className }: ListProps) => {
  const margins = {
    none: '',
    small: 'ml-3',
    medium: 'ml-5',
    large: 'ml-10',
  }

  const gaps = {
    small: 'space-y-0',
    medium: 'space-y-1',
    large: 'space-y-2',
    xl: 'space-y-3',
  }

  const listStyles = {
    default: '',
    none: 'list-none',
    disc: 'list-disc',
    number: 'list-decimal',
  }

  const ui = useUi({
    styles: {
      type: { options: listStyles, selected: listStyle },
      gaps: { options: gaps, selected: gap },
      margin: { options: margins, selected: margin },
    },
    name: 'List',
    className,
  })

  return (
    <ul {...ui.attributes} role="list" className={ui.className}>
      {children}
    </ul>
  )
}

List.displayName = 'List'

export default List
