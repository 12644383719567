import { useUi, type Breakpoints } from '../use-ui'
import { ButtonPropsShared } from './index'

export type RoundButtonTypes = 'submit' | 'button' | 'text'

export type RoundButtonColors =
  | 'danger'
  | 'danger_outline'
  | 'info'
  | 'info_outline'
  | 'primary'
  | 'primary_outline'
  | 'secondary'
  | 'secondary_outline'
  | 'success'
  | 'success_outline'
  | 'warning'
  | 'warning_outline'
  | 'light'
  | 'pink'

export type RoundButtonSizes = 'fit' | 'medium' | 'large' | 'xl' | 'full'

export type RoundButtonProps = {
  color?: RoundButtonColors
  size?: Breakpoints<RoundButtonSizes>
  type?: RoundButtonTypes
  textSize?: never
} & ButtonPropsShared

export const RoundButton = ({
  color = 'primary',
  size = 'fit',
  type,
  disabled,
  className,
  uiComponent,
  isRunning,
  children,
  ...htmlProps
}: Omit<RoundButtonProps, 'errorMessage'>) => {
  const colors = {
    default: ` ${
      (disabled || isRunning) && `opacity-70 ${disabled && 'cursor-not-allowed'} ${isRunning && 'cursor-wait'}`
    }`,

    light: 'border-2 border-white bg-white hover:bg-gray-200 text-black',

    danger: type === 'text' ? 'text-red-600' : 'border-2 border-red-200 bg-red-300 hover:bg-red-400 text-red-700',
    danger_outline: 'border-2 border-red-50 bg-red-50 hover:bg-red-200 text-red-600',

    info: type === 'text' ? 'text-cyan-600' : 'border border-cyan-300 bg-cyan-400 hover:bg-cyan-500 text-cyan-700',
    info_outline: 'border-2 border-cyan-50 bg-cyan-50 hover:bg-cyan-200 text-cyan-600',

    primary:
      type === 'text'
        ? 'text-selphBlue-600'
        : 'border-2 border-selphGrey-100 bg-selphGrey-200 hover:bg-[#C9D0D9] active:bg-[#BFC5CD] text-selphBlue-700',
    primary_outline: 'border-2 border-white bg-selphGrey-50 hover:bg-selphGrey-200 text-selphGrey-700',

    pink:
      type === 'text'
        ? 'text-hotPink'
        : 'border-2 border-white bg-selphGrey-200 hover:bg-[#C9D0D9] active:bg-[#BFC5CD] text-hotPink',

    secondary:
      type === 'text'
        ? 'text-gray-600'
        : 'border-2 border-selphBlue-100 bg-selphBlue-100 hover:bg-selphBlue-200 text-selphBlue-500',
    secondary_outline: 'border-2 border-selphBlue-50 bg-white hover:bg-selphBlue-100 text-selphBlue-500',

    success:
      type === 'text' ? 'text-green-600' : 'border-2 border-green-300 bg-green-400 hover:bg-green-500 text-green-800',
    success_outline: 'border-2 border-green-100 bg-green-100 hover:bg-green-200 text-green-700',

    warning:
      type === 'text'
        ? 'text-yellow-600'
        : 'border-2 border-yellow-300 bg-yellow-300 hover:bg-yellow-400 text-yellow-700',
    warning_outline: 'border-2 border-yellow-50 bg-yellow-50 hover:bg-yellow-200 text-yellow-600',
  }

  const backgroundPatternSolid =
    'bg-[radial-gradient(circle,white_10%,rgb(255,255,255,0))] hover:bg-[radial-gradient(circle,#F2F2F2_10%,rgb(242,242,242,0))] active:bg-[radial-gradient(circle,#E6E6E6_10%,rbg(230,230,230,0))]'

  const backgroundPatterns = {
    default: '',
    pink: backgroundPatternSolid,
    light: backgroundPatternSolid,
    danger: backgroundPatternSolid,
    danger_outline: '',
    info: backgroundPatternSolid,
    info_outline: '',
    primary: backgroundPatternSolid,
    primary_outline: '',
    secondary: backgroundPatternSolid,
    secondary_outline: '',
    success: backgroundPatternSolid,
    success_outline: '',
    warning: backgroundPatternSolid,
    warning_outline: '',
  }

  const borderColors = {
    default: '',
    pink: '#e22e89', // Tailwind: fill-[#e22e89]
    light: 'gray',
    danger: '#dc2626',
    danger_outline: '#dc2626', // Tailwind: fill-[#dc2626]
    info: '#0891b2',
    info_outline: '#0891b2',
    primary: '#346cad', //Tailwind: fill-[#346cad]
    primary_outline: '#54626e',
    secondary: '#0d4780',
    secondary_outline: '#0d4780',
    success: '#15803d', // Tailwind: fill-[#15803d]
    success_outline: '#15803d',
    warning: '#ca8a04', // Tailwind: fill-[#ca8a04]
    warning_outline: '#ca8a04',
  }

  const sizes = {
    default: 'leading-4',
    fit: type === 'text' ? 'text-base' : 'max-w-fit px-4', // Tailwind: sm:max-w-fit sm:px-4  md:max-w-fit md:px-4  lg:max-w-fit lg:px-4  xl:max-w-fit xl:px-4
    medium: type === 'text' ? 'text-base' : 'max-w-fit px-6', // Tailwind: sm:px-6  md:px-6   lg:px-6  xl:px-6
    large: type === 'text' ? 'text-base' : 'max-w-fit px-10', // Tailwind: sm:px-10  md:px-10  lg:px-10  xl:px-10
    xl: type === 'text' ? 'text-base' : 'max-w-fit px-12 text-lg', // Tailwind: sm:px-12  md:px-12  lg:px-12  xl:px-12
    full: 'w-full', // Tailwind: sm:w-full  md:w-full   lg:w-full  xl:w-full
  }

  const types = {
    text: 'p-0 w-fit border-0',
    button: 'h-10 relative min-w-28 rounded-full',
    submit: 'h-10 relative min-w-28 rounded-full',
  }

  const ui = useUi({
    styles: {
      color: { options: colors, selected: color },
      size: { options: sizes, selected: size },
      types: { options: types, selected: type },
    },
    name: uiComponent || 'Button',
    className,
  })

  const backgroundUI = useUi({
    styles: {
      color: { options: backgroundPatterns, selected: color },
    },
    name: uiComponent || 'Button',
  })

  return (
    <button
      {...ui.attributes}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled || isRunning}
      {...htmlProps}
      className={`${ui.className} overflow-hidden shadow-lg drop-shadow-sm active:drop-shadow-none active:shadow-none`}
    >
      <div className={`${backgroundUI.className} absolute rounded-full w-1/3 h-20 bottom-[10%] left-4 blur-md`} />
      <div className={`${backgroundUI.className} absolute rounded-full h-full w-1/2 -right-[10%] top-1/4 blur-md`} />

      <svg
        width="99%"
        height="96%"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        className="overflow-visible absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <defs>
          <linearGradient
            id={`round-grad-${borderColors[color]}${htmlProps.id ? `-${htmlProps.id}` : ''}`}
            gradientTransform="rotate(-20)"
          >
            <stop offset="0" stopColor={borderColors[color]} />
            <stop offset="0.20" stopColor={borderColors[color]} stopOpacity="0.05" />
            <stop offset="0.40" stopColor={borderColors[color]} stopOpacity="0.05" />
            <stop offset="1" stopColor={borderColors[color]} />
          </linearGradient>
        </defs>

        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          rx="16"
          ry="16"
          fill="none"
          stroke={`url(#round-grad-${borderColors[color]}${htmlProps.id ? `-${htmlProps.id}` : ''})`}
          strokeWidth="1px"
          vectorEffect="non-scaling-stroke"
        />
      </svg>

      <span className="relative z-10 font-semibold whitespace-nowrap">
        {isRunning ? (
          <svg
            aria-hidden="true"
            className={`inline-block mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-[${borderColors[color]}]`}
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : (
          <> {children}</>
        )}
      </span>
    </button>
  )
}

export default RoundButton
