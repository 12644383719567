import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'
import cookies from 'js-cookie'
import generatedIntrospection from '../gql/possible-types'

const getImpersonationHeaders = () => {
  if (typeof window === 'undefined') {
    return {}
  }

  const id = cookies.get('impersonateUserId')
  if (!id) return {}

  return { 'x-impersonate-user-id': id }
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      console.log('GraphQL error', graphQLErrors, networkError)
    }),

    setContext(async (_, { headers }) => {
      const idToken = typeof window !== 'undefined' ? window?.localStorage?.getItem('idToken') : null

      return {
        headers: {
          ...headers,
          Authorization: idToken,
          ...getImpersonationHeaders(),
        },
      }
    }),

    createHttpLink({
      uri: process.env.BUILD_GRAPHQL_URL || '/graphql',
      fetch,
      credentials: 'omit',
    }),
  ]),

  cache: new InMemoryCache({ possibleTypes: generatedIntrospection.possibleTypes }),
})
