import * as React from 'react'
import { BasicButton, BasicButtonProps, BasicButtonSizes, BasicButtonColors } from './basic-button'
import { RoundButton, RoundButtonProps, RoundButtonColors, RoundButtonSizes } from './round-button'
import { CircleButton, CircleButtonProps } from './circle-button'
import { SolidButton, SolidButtonProps } from './solid-button'
import * as UI from '@/ui'

export type ButtonColors = BasicButtonColors | RoundButtonColors
export type ButtonSizes = BasicButtonSizes | RoundButtonSizes
export type ButtonThemes =
  | ButtonPropsBasic['theme']
  | ButtonPropsCircle['theme']
  | ButtonPropsRound['theme']
  | ButtonPropsSolid['theme']

export type ButtonProps = ButtonPropsBasic | ButtonPropsCircle | ButtonPropsRound | ButtonPropsSolid

export interface ButtonPropsShared extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'color' | 'type'> {
  disabled?: boolean
  className?: string
  rootClassName?: string
  uiComponent?: string
  icon?: React.FC<React.ComponentProps<'svg'>>
  isRunning?: boolean
  errorMessage?: string | null
  children?: React.ReactNode
}

export type ButtonPropsBasic = {
  theme?: 'basic'
} & BasicButtonProps

export type ButtonPropsCircle = {
  theme?: 'circle'
} & CircleButtonProps

export type ButtonPropsRound = {
  theme?: 'round'
} & RoundButtonProps

export type ButtonPropsSolid = {
  theme?: 'solid'
} & SolidButtonProps

export const Button = ({
  errorMessage,
  rootClassName = '',
  children,
  icon,
  size,
  type = 'button',
  theme = 'solid',
  textSize,
  ...props
}: ButtonProps) => {
  const Icon = icon
  theme = type === 'text' ? 'basic' : theme

  return (
    <div
      className={`${rootClassName} ${
        ['xs', 'small', 'medium'].includes(size as BasicButtonSizes) ? 'inline-block ' : 'block'
      }`}
    >
      <UI.Block gap="xs" className={['xs', 'small', 'medium'].includes(size as BasicButtonSizes) ? 'w-fit' : 'w-full'}>
        {
          {
            basic: (
              <BasicButton
                type={type as ButtonPropsBasic['type']}
                size={size as ButtonPropsBasic['size']}
                {...(props as Omit<ButtonPropsBasic, 'size' | 'type' | 'theme'>)}
              >
                {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                {children}
              </BasicButton>
            ),
            circle: (
              <CircleButton
                type={type as CircleButtonProps['type']}
                size={size as CircleButtonProps['size']}
                textSize={textSize as CircleButtonProps['textSize']}
                {...(props as Omit<CircleButtonProps, 'size' | 'type' | 'theme'>)}
              >
                {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                {children}
              </CircleButton>
            ),
            round: (
              <RoundButton
                type={type as ButtonPropsRound['type']}
                size={size as ButtonPropsRound['size']}
                {...(props as Omit<RoundButtonProps, 'size' | 'type' | 'theme'>)}
              >
                {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                {children}
              </RoundButton>
            ),
            solid: (
              <SolidButton
                type={type as ButtonPropsSolid['type']}
                size={size as ButtonPropsSolid['size']}
                textSize={textSize as SolidButtonProps['textSize']}
                {...(props as Omit<SolidButtonProps, 'size' | 'type' | 'theme'>)}
              >
                {Icon && <Icon className={`inline-block w-5 h-5 ${children ? 'mr-1' : ''}`} />}
                {children}
              </SolidButton>
            ),
          }[theme]
        }

        {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
      </UI.Block>
    </div>
  )
}

Button.displayName = 'Button'

export default Button
