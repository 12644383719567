import { useUi, type Breakpoints } from '../use-ui'
import { ButtonPropsShared } from './index'

export type SolidButtonTypes = 'submit' | 'button'
export type SolidButtonColors = 'light' | 'danger' | 'info' | 'primary' | 'pink' | 'secondary' | 'success' | 'warning'
export type SolidButtonSizes = 'fit' | 'small' | 'medium' | 'large' | 'xl' | 'full'

export type SolidButtonProps = {
  color?: SolidButtonColors
  size?: Breakpoints<SolidButtonSizes>
  type?: SolidButtonTypes
  textSize?: string // Tailwind class: text-xs text-sm text-base text-lg text-xl
} & ButtonPropsShared

export const SolidButton = ({
  color = 'primary',
  size = 'fit',
  type,
  textSize,
  disabled,
  className,
  uiComponent,
  isRunning,
  children,
  ...htmlProps
}: Omit<SolidButtonProps, 'errorMessage'>) => {
  const colors = {
    default: `${
      (disabled || isRunning) && `opacity-70 ${disabled && 'cursor-not-allowed'} ${isRunning && 'cursor-wait'}`
    }`,

    light: 'bg-white hover:bg-gray-200 text-black',
    danger: 'bg-red-300 hover:bg-red-400 text-red-700',
    info: 'bg-cyan-400 hover:bg-cyan-500 text-cyan-700',
    primary: 'bg-selphBlue-300 hover:bg-selphBlue-400 active:bg-selphBlue-500 text-white',
    pink: 'bg-hotPink-500 hover:bg-hotPink-600 active:bg-hotPink-800 text-white',
    secondary: 'bg-selphBlue-100 hover:bg-selphBlue-200 text-selphBlue-500',
    success: 'bg-green-400 hover:bg-green-500 text-green-800',
    warning: 'bg-yellow-300 hover:bg-yellow-400 text-yellow-700',
  }

  const borderColors = {
    default: '',
    light: 'gray',
    danger: '#dc2626',
    info: '#0891b2',
    primary: '#346cad',
    pink: '#e22e89',
    secondary: '#0d4780',
    success: '#15803d',
    warning: '#ca8a04',
  }

  const sizes = {
    default: 'leading-5',
    small: 'px-0 py-1', // Tailwind: sm:max-w-fit sm:px-4  md:max-w-fit md:px-4  lg:max-w-fit lg:px-4  xl:max-w-fit xl:px-4
    medium: 'max-w-fit px-6 py-1', // Tailwind: sm:px-6  md:px-6   lg:px-6  xl:px-6
    large: 'max-w-fit px-10 py-2', // Tailwind: sm:px-10  md:px-10  lg:px-10  xl:px-10
    xl: 'max-w-fit px-14 py-2 text-lg', // Tailwind: sm:px-12  md:px-12  lg:px-12  xl:px-12
    full: 'w-full px-6 py-2.5', // Tailwind: sm:w-full  md:w-full   lg:w-full  xl:w-full
    fit: 'max-w-fit px-8 py-1', // Tailwind: sm:max-w-fit sm:px-4  md:max-w-fit md:px-4  lg:max-w-fit lg:px-4  xl:max-w-fit xl:px-4
  }

  const types = {
    button: 'min-h-10 relative min-w-28 rounded-full',
    submit: 'min-h-10 relative min-w-28 rounded-full',
  }

  const ui = useUi({
    styles: {
      color: { options: colors, selected: color },
      size: { options: sizes, selected: size },
      types: { options: types, selected: type },
    },
    name: uiComponent || 'Button',
    className,
  })

  return (
    <button
      {...ui.attributes}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled || isRunning}
      {...htmlProps}
      className={`${ui.className} shadow-lg drop-shadow-sm active:drop-shadow-none active:shadow-none`}
    >
      <span className={`${textSize} relative z-10 py-4 font-semibold whitespace-normal tracking-wider`}>
        {isRunning ? (
          <svg
            aria-hidden="true"
            className={`inline-block mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-[${borderColors[color]}]`}
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : (
          <span className="flex flex-wrap items-center justify-center">{children}</span>
        )}
      </span>
    </button>
  )
}

export default SolidButton
