import { useState } from 'react'
import { type Breakpoints } from '../../use-ui'

import { Label } from '../label'
import { HelpText } from '../help-text'
import { InputText, type InputTextProps } from './input-text'
import { FormikText, type FormikTextProps } from './formik-text'
import { FormikPassword, type FormikPasswordProps } from './formik-password'
import * as UI from '@/ui'

export type TextSize = 'medium'

export type TextProps = TextPropsFormik | TextPropsPassword | TextPropsInput

export type TextPropsCommon = {
  name: string
  field?: 'text' | 'password'
  placeholder?: string
  errorMessage?: string
  theme?: 'light' | 'dark'
  containerClassName?: string
  boxSize?: Breakpoints<TextSize>
  readOnly?: 'readOnly'
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'readOnly'>

export type TextPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string | React.ReactNode

  children?: React.ReactNode
}

export type TextPropsFormik = FormikTextProps &
  TextPropsShared & {
    type?: 'formik'
  }

export type TextPropsPassword = FormikPasswordProps &
  TextPropsShared & {
    type?: 'password'
  }

export type TextPropsInput = InputTextProps &
  TextPropsShared & {
    type?: 'input'
  }

export const boxSizes = {
  default: `block w-full focus:outline-none sm:text-sm rounded-md`,
  medium: '',
}

export const Text = ({
  name,
  type = 'formik',
  helpTextAbove,
  helpTextBelow,
  children,
  containerClassName = '',
  ...props
}: TextProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  return (
    <div className={`${containerClassName} ${isHidden ? 'hidden' : ''}`}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText className="-mb-1">{helpTextAbove}</HelpText>}

        {type === 'formik' && <FormikText name={name} setIsHidden={setIsHidden} {...props} />}
        {type === 'password' && <FormikPassword name={name} setIsHidden={setIsHidden} {...props} />}
        {type === 'input' && <InputText name={name} {...props} />}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

Text.displayName = 'Form.Text'

export default Text
