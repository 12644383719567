import cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { XMarkIcon } from '@heroicons/react/24/outline'
import * as UI from '@/ui'

interface CookieOptions {
  expires: number
}

function startTracking() {
  ReactGA.event('start_tracking')
}

const Tracking = () => {
  const [isVisible, setIsVisible] = useState(false)

  const storeConsentCookie = (value: string, options?: CookieOptions) => {
    setIsVisible(false)
    cookies.set('cookieConsent', value, options)
  }

  // If we get explicit acceptance or rejection, remember it for 'ever'
  const onReject = () => storeConsentCookie('false', { expires: 365 })
  const onAccept = () => {
    storeConsentCookie('true', { expires: 365 })
    startTracking()
  }

  // If the prompt is just dismissed, don't show it again for the session
  // so the user will be asked again next visit
  // TODO: Temporarily removed close button. Either remove completely
  // or restore, depending if we see more acceptance
  // const onDismiss = () => storeConsentCookie(false)

  useEffect(
    () => {
      const consentGiven = cookies.get('cookieConsent')
      if (consentGiven === undefined) {
        setIsVisible(true)
      }

      if (consentGiven === 'false') {
        // Do not track
        console.log('Disabling tracking due to user opt-out')
      }

      if (consentGiven === 'true') {
        startTracking()
      }
    },
    [
      /* Should only init tracking once */
    ],
  )

  if (!isVisible) {
    return null
  }

  return (
    <div className="fixed rounded-xl shadow-lg max-w-md bg-white z-20 bottom-2 md:bottom-5 right-2 md:right-5 border-2 border-selphBlue-400 py-2 px-2">
      <div className="absolute top-0 right-0 py-2 pr-2">
        <button
          type="button"
          className="rounded-md  text-gray-400 hover:text-gray-500"
          onClick={() => setIsVisible(false)}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div>
        <p className="pr-5 text-selphGrey-800">
          This website uses cookies and also collects some information using Google Analytics. Please review our{' '}
          <UI.Link to="cookie">Cookie Policy</UI.Link> and <UI.Link to="privacy">Privacy Policy</UI.Link> agreements.
        </p>
        <div className="flex justify-between items-baseline mt-5">
          <UI.Button type="text" color="secondary" onClick={onReject}>
            Don&apos;t use cookies
          </UI.Button>

          <UI.Button theme="solid" color="primary" onClick={onAccept}>
            Accept
          </UI.Button>
        </div>
      </div>
    </div>
  )
}

export default Tracking
