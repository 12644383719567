import * as UI from '@/ui'

export type UserType = UserTypeNew | UserTypeAccount

export type UserTypeShared = {
  authenticated?: boolean
  cognitoId?: string
  emailAddress: string
  deliveryAddress?: UI.Form.AddressValues
  biologicalSex?: 'male' | 'female'
  phoneNumber?: string | null
  groups?: string[]
  isAdmin?: boolean
}

export type UserTypeNew = {
  newUser?: boolean
  firstName?: string | null
  lastName?: string | null
  friendlyName?: string | null
} & UserTypeShared

export type UserTypeAccount = {
  newUser?: never
  firstName?: string | null
  lastName?: string | null
  friendlyName?: string | null
} & UserTypeShared

export class User {
  public authenticated: UserType['authenticated']
  public cognitoId: UserType['cognitoId']
  public firstName: UserType['firstName']
  public lastName: UserType['lastName']
  public friendlyName: UserType['friendlyName']
  public biologicalSex: UserType['biologicalSex']
  public phoneNumber: UserType['phoneNumber']
  public emailAddress: UserType['emailAddress']
  public deliveryAddress: UserType['deliveryAddress']
  public newUser: UserType['newUser']
  public groups: UserType['groups']
  public isAdmin: boolean

  public constructor({
    authenticated,
    cognitoId,
    firstName,
    lastName,
    friendlyName,
    emailAddress,
    deliveryAddress,
    biologicalSex,
    phoneNumber,
    newUser,
    groups,
    isAdmin,
  }: UserType) {
    this.authenticated = authenticated
    this.cognitoId = cognitoId
    this.firstName = firstName
    this.lastName = lastName
    this.friendlyName = friendlyName
    this.biologicalSex = biologicalSex
    this.phoneNumber = phoneNumber
    this.emailAddress = emailAddress
    this.deliveryAddress = deliveryAddress
    this.newUser = newUser
    this.groups = groups
    this.isAdmin = isAdmin || false
  }

  public get isGuest() {
    return (!this.authenticated && this.newUser) || false
  }

  public get isLoggedIn() {
    return (this.authenticated && !this.newUser) || false
  }

  public get isCustomer() {
    return !this.isAdmin
  }

  public get isAdministrator() {
    return this.isAdmin
  }
}

export { Authenticated } from './authenticated'
