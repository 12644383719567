import { ButtonPropsShared } from './index'

export type CircleButtonTypes = 'submit' | 'button'

export type CircleButtonProps = {
  size?: string
  textSize?: string
  type?: CircleButtonTypes
  color?: never
} & ButtonPropsShared

export const CircleButton = ({
  size = 'w-36 h-36',
  textSize = 'text-xl leading-6',
  type = 'button',
  disabled,
  className,
  isRunning,
  children,
  ...htmlProps
}: Omit<CircleButtonProps, 'errorMessage'>) => {
  return (
    <button
      disabled={disabled || isRunning}
      {...htmlProps}
      type={type}
      className={`${size} ${className} relative rounded-full bg-[#BBC0D0] border-2 border-white shadow-lg overflow-hidden drop-shadow-lg hover:bg-[#B2B6C6] active:bg-[#A8ADBB] active:drop-shadow-none`}
    >
      <div
        className={`absolute bg-[radial-gradient(circle,white_10%,rgb(255,255,255,0))] hover:bg-[radial-gradient(circle,#F2F2F2_10%,rgb(242,242,242,0))] active:bg-[radial-gradient(circle,#E6E6E6_10%,rbg(230,230,230,0))] rounded-full h-2/3 w-2/3 -top-5 -left-5 blur-md`}
      />
      <div
        className={`absolute bg-[radial-gradient(circle,white_10%,rgb(255,255,255,0))] hover:bg-[radial-gradient(circle,#F2F2F2_10%,rgb(242,242,242,0))] active:bg-[radial-gradient(circle,#E6E6E6_10%,rgb(230,230,230,0))] rounded-full h-2/3 w-2/3 -bottom-5 -right-5 blur-md`}
      />

      <svg
        width="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <defs>
          <linearGradient id={`circle-grad-${htmlProps.id}`}>
            <stop offset="0" stopColor="#e22e89" />
            <stop offset="0.4" stopColor="#e22e89" stopOpacity="0" />
            <stop offset="0.6" stopColor="#e22e89" stopOpacity="0" />
            <stop offset="1" stopColor="#e22e89" />
          </linearGradient>
        </defs>

        <circle
          cx="50"
          cy="50"
          r="47"
          fill="none"
          stroke={`url(#circle-grad-${htmlProps.id})`}
          vectorEffect="non-scaling-stroke"
          strokeWidth="2px"
          transform="rotate(-45, 50,50)"
        />
      </svg>
      <span
        className={`${textSize} absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 font-thin max-h-fit font-museoSans text-hotPink-500`}
      >
        {children}
      </span>
    </button>
  )
}

export default CircleButton
