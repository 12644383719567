import { useUi, Breakpoints } from '../use-ui'
import { ButtonPropsShared } from './index'

export type BasicButtonTypes = 'submit' | 'button' | 'text'

export type BasicButtonColors =
  | 'danger'
  | 'danger_outline'
  | 'info'
  | 'info_outline'
  | 'primary'
  | 'primary_outline'
  | 'secondary'
  | 'secondary_outline'
  | 'success'
  | 'success_outline'
  | 'warning'
  | 'warning_outline'
  | 'light'
  | 'dark'
  | 'dark_outline'
export type BasicButtonSizes = 'squareFull' | 'xs' | 'xsFull' | 'small' | 'smallFull' | 'medium' | 'mediumFull'

export type BasicButtonProps = {
  color?: Breakpoints<BasicButtonColors>
  size?: Breakpoints<BasicButtonSizes>
  type?: BasicButtonTypes
  textSize?: never
} & ButtonPropsShared

export const BasicButton = ({
  color = 'primary',
  size = 'medium',
  type,
  disabled,
  className,
  uiComponent,
  isRunning,
  children,
  ...htmlProps
}: Omit<BasicButtonProps, 'errorMessage'>) => {
  const colors = {
    default: ` ${
      (disabled || isRunning) && `opacity-70 ${disabled && 'cursor-not-allowed'} ${isRunning && 'cursor-wait'}`
    }`,

    light: 'border-2 bg-white border-grey-500 hover:bg-gray-200 text-black', // Tailwind: sm:bg-white sm:border-black-500 sm:hover:bg-gray-200 sm:text-black-600  md:bg-white md:border-black-500 md:hover:bg-gray-200 md:text-black-600  lg:bg-white lg:border-black-500 lg:hover:bg-gray-200 lg:text-black-600  xl:bg-white xl:border-black-500 xl:hover:bg-gray-200 xl:text-black-600

    dark: type === 'text' ? 'text-black' : 'border-2 border-black bg-black hover:bg-gray-600 text-white', // Tailwind: sm:border-black sm:bg-black sm:hover:bg-gray-600 sm:text-white  md:border-black md:bg-black md:hover:bg-gray-600 md:text-white  lg:border-black lg:bg-black lg:hover:bg-gray-600 lg:text-white  xl:border-black xl:black xl:hover:bg-gray-600 xl:text-white
    dark_outline: 'border-2 bg-white border-black hover:bg-gray-200 text-black', // Tailwind: sm:bg-white sm:border-black sm:hover:bg-gray-200 sm:text-black  md:bg-white md:border-black md:hover:bg-gray-200 md:text-black  lg:bg-white lg:border-black lg:hover:bg-gray-200 lg:text-black  xl:bg-white xl:border-black xl:hover:bg-gray-200 xl:text-black

    danger: type === 'text' ? 'text-red-600' : 'border-2 border-red-500 bg-red-500 hover:bg-red-700 text-white', // Tailwind: sm:border-red-100 sm:text-red-600 sm:bg-red-500 sm:hover:bg-red-700 sm:text-white  md:border-red-100 md:text-red-600 md:bg-red-500 md:hover:bg-red-700 md:text-white  lg:border-red-100 lg:text-red-600 lg:bg-red-500 lg:hover:bg-red-700 lg:text-white  xl:border-red-100 xl:text-red-600 xl:bg-red-500 xl:hover:bg-red-700 xl:text-white
    danger_outline: 'border-2 bg-red-50 border-red-500 hover:bg-red-200 text-red-600', // Tailwind: sm:bg-red-50 sm:border-red-500 sm:hover:bg-red-200 sm:text-red-600  md:bg-red-50 md:border-red-500 md:hover:bg-red-200 md:text-red-600  lg:bg-red-50 lg:border-red-500 lg:hover:bg-red-200 lg:text-red-600  xl:bg-red-50 xl:border-red-500 xl:hover:bg-red-200 xl:text-red-600

    info: type === 'text' ? 'text-cyan-600' : 'border-2 border-cyan-500 bg-cyan-500 hover:bg-cyan-700 text-white', // Tailwind: sm:border-cyan-100 sm:text-cyan-600 sm:bg-cyan-500 sm:hover:bg-cyan-700 sm:text-white  md:border-cyan-100 md:text-cyan-600 md:bg-cyan-500 md:hover:bg-cyan-700 md:text-white  lg:border-cyan-100 lg:text-cyan-600 lg:bg-cyan-500 lg:hover:bg-cyan-700 lg:text-white  xl:border-cyan-100 xl:text-cyan-600 xl:bg-cyan-500 xl:hover:bg-cyan-700 xl:text-white
    info_outline: 'border-2 bg-cyan-50 border-cyan-500 hover:bg-cyan-200 text-cyan-600', // Tailwind: sm:bg-cyan-50 sm:border-cyan-500 sm:hover:bg-cyan-200 sm:text-cyan-600  md:bg-cyan-50 md:border-cyan-500 md:hover:bg-cyan-200 md:text-cyan-600  lg:bg-cyan-50 lg:border-cyan-500 lg:hover:bg-cyan-200 lg:text-cyan-600  xl:bg-cyan-50 xl:border-cyan-500 xl:hover:bg-cyan-200 xl:text-cyan-600

    primary:
      type === 'text' ? 'text-selphBlue-300' : 'border-2 border-blue-500 bg-blue-500 hover:bg-blue-700 text-white', // Tailwind: sm:border-blue-100 sm:text-blue-600 sm:bg-blue-500 sm:hover:bg-blue-700 sm:text-white  md:border-blue-100 md:text-blue-600 md:bg-blue-500 md:hover:bg-blue-700 md:text-white  lg:border-blue-100 lg:text-blue-600 lg:bg-blue-500 lg:hover:bg-blue-700 lg:text-white  xl:border-blue-100 xl:text-blue-600 xl:bg-blue-500 xl:hover:bg-blue-700 xl:text-white
    primary_outline: 'border-2 border-selphBlue-300 hover:bg-blue-50 text-selphBlue-400', // Tailwind: sm:bg-blue-50 sm:border-blue-500 sm:hover:bg-blue-200 sm:text-blue-600  md:bg-blue-50 md:border-blue-500 md:hover:bg-blue-200 md:text-blue-600  lg:bg-blue-50 lg:border-blue-500 lg:hover:bg-blue-200 lg:text-blue-600  xl:bg-blue-50 xl:border-blue-500 xl:hover:bg-blue-200 xl:text-blue-600

    secondary: type === 'text' ? 'text-gray-600' : 'border-2 border-gray-500 bg-gray-500 hover:bg-gray-700 text-white', // Tailwind: sm:border-gray-100 sm:text-gray-600 sm:bg-gray-500 sm:hover:bg-gray-700 sm:text-white  md:border-gray-100 md:text-gray-600 md:bg-gray-500 md:hover:bg-gray-700 md:text-white  lg:border-gray-100 lg:text-gray-600 lg:bg-gray-500 lg:hover:bg-gray-700 lg:text-white  xl:border-gray-100 xl:text-gray-600 xl:bg-gray-500 xl:hover:bg-gray-700 xl:text-white
    secondary_outline: 'border-2 bg-gray-50 border-gray-500 hover:bg-gray-200 text-gray-600', // Tailwind: sm:bg-gray-50 sm:border-gray-500 sm:hover:bg-gray-200 sm:text-gray-600  md:bg-gray-50 md:border-gray-500 md:hover:bg-gray-200 md:text-gray-600  lg:bg-gray-50 lg:border-gray-500 lg:hover:bg-gray-200 lg:text-gray-600  xl:bg-gray-50 xl:border-gray-500 xl:hover:bg-gray-200 xl:text-gray-600

    success:
      type === 'text' ? 'text-green-600' : 'border-2 border-green-500 bg-green-500 hover:bg-green-700 text-white', // Tailwind: sm:border-green-100 sm:text-green-600 sm:bg-green-500 sm:hover:bg-green-700 sm:text-white  md:border-green-100 md:text-green-600 md:bg-green-500 md:hover:bg-green-700 md:text-white  lg:border-green-100 lg:text-green-600 lg:bg-green-500 lg:hover:bg-green-700 lg:text-white  xl:border-green-100 xl:text-green-600 xl:bg-green-500 xl:hover:bg-green-700 xl:text-white
    success_outline: 'border-2 bg-green-100 border-green-500 hover:bg-green-200 text-green-600', // Tailwind: sm:bg-green-100 sm:border-green-500 sm:hover:bg-green-200 sm:text-green-600  md:bg-green-100 md:border-green-500 md:hover:bg-green-200 md:text-green-600  lg:bg-green-100 lg:border-green-500 lg:hover:bg-green-200 lg:text-green-600  xl:bg-green-100 xl:border-green-500 xl:hover:bg-green-200 xl:text-green-600

    warning:
      type === 'text' ? 'text-yellow-600' : 'border-2 border-yellow-300 bg-yellow-300 hover:bg-yellow-400 text-black', // Tailwind: sm:border-yellow-100 sm:text-yellow-600 sm:bg-yellow-500 sm:hover:bg-yellow-700 sm:text-white  md:border-yellow-100 smd:text-yellow-600 md:bg-yellow-500 md:hover:bg-yellow-700 md:text-white  lg:border-yellow-100 slg:text-yellow-600 lg:bg-yellow-500 lg:hover:bg-yellow-700 lg:text-white  xl:border-yellow-100 sxl:text-yellow-600 xl:bg-yellow-500 xl:hover:bg-yellow-700 xl:text-white

    warning_outline: 'border-2 bg-yellow-50 border-yellow-500 hover:bg-yellow-200 text-yellow-600', // Tailwind: sm:bg-yellow-50 sm:border-yellow-500 sm:hover:bg-yellow-200 sm:text-yellow-600  md:bg-yellow-50 md:border-yellow-500 md:hover:bg-yellow-200 md:text-yellow-600  lg:bg-yellow-50 lg:border-yellow-500 lg:hover:bg-yellow-200 lg:text-yellow-600  xl:bg-yellow-50 xl:border-yellow-500 xl:hover:bg-yellow-200 xl:text-yellow-600
  }

  const sizes = {
    default: '',

    squareFull: 'w-fit aspect-square h-full', // Tailwind: sm:w-fit sm:aspect-square sm:h-full  md:w-fit md:aspect-square md:h-full  lg:w-fit lg:aspect-square lg:h-full  xl:w-fit xl:aspect-square xl:h-full

    xs: type === 'text' ? 'text-sm' : 'w-fit leading-4 py-0 px-0 text-sm', // Tailwind: sm:py-0 sm:px-0 sm:text-sm  md:py-0 md:px-0 md:text-sm  lg:py-0 lg:px-0 lg:text-sm  xl:py-0 xl:px-0 xl:text-sm
    xsFull: 'leading-4 py-0 px-0 text-sm w-full', // Tailwind: sm:py-0 sm:px-0 sm:text-sm sm:w-full  md:py-0 md:px-0 md:text-sm md:w-full  lg:py-0 lg:px-0 lg:text-sm lg:w-full  xl:py-0 xl:px-0 xl:text-sm xl:w-full

    small: type === 'text' ? 'text-base' : 'w-fit leading-4 py-1 px-2', // Tailwind: sm:text-base sm:py-1 sm:px-2  md:text-base md:py-1 md:px-2  lg:text-base lg:py-1 lg:px-2  xl:text-base xl:py-1 xl:px-2
    smallFull: 'leading-4 py-1 px-2 w-full', // Tailwind: sm:py-1 sm:px-2 sm:w-full  md:py-1 md:px-2 md:w-full  lg:py-1 lg:px-2 lg:w-full  xl:py-1 xl:px-2 xl:w-full

    medium: type === 'text' ? 'text-base' : 'min-w-24 max-w-fit leading-4 py-2 px-4', // Tailwind: sm:min-w-24 sm:max-w-fit sm:text-base sm:py-2 sm:px-4  md:min-w-24 md:max-w-fit md:text-base md:py-2 md:px-4  lg:min-w-24 lg:max-w-fit lg:text-base lg:py-2 lg:px-4  xl:min-w-24 xl:max-w-fit xl:text-base xl:py-2 xl:px-4
    mediumFull: 'leading-4 py-2 px-4 w-full', // Tailwind: sm:py-2 sm:px-4 sm:w-full  md:py-2 md:px-4 md:w-full  lg:py-2 lg:px-4 lg:w-full  xl:py-2 xl:px-4 xl:w-full
  }

  const types = {
    text: 'p-0 w-fit border-0', // Tailwind: sm:p-0 sm:w-fit sm:border-0  md:p-0 md:w-fit md:border-0  lg:p-0 lg:w-fit lg:border-0  xl:p-0 xl:w-fit xl:border-0
    button: 'rounded-lg font-bold', // Tailwind: sm:rounded sm:font-bold  md:rounded md:font-bold  lg:rounded lg:font-bold  xl:rounded xl:font-bold
    submit: 'rounded-lg font-bold', // Tailwind: sm:rounded sm:font-bold  md:rounded md:font-bold  lg:rounded lg:font-bold  xl:rounded xl:font-bold
  }

  const ui = useUi({
    styles: {
      color: { options: colors, selected: color },
      size: { options: sizes, selected: size },
      types: { options: types, selected: type },
    },
    name: uiComponent || 'Button',
    className,
  })

  return (
    <button
      {...ui.attributes}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled || isRunning}
      {...htmlProps}
      className={ui.className}
    >
      {isRunning ? (
        <svg
          aria-hidden="true"
          className="inline-block mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      ) : (
        children
      )}
    </button>
  )
}

export default BasicButton
