import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import TPLogoWhite from '/public/images/TP-Logo-White.svg?svgr'
import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustData } from './trustpilot-box'

export const TPMicro = ({ trustData, darkBg }: { trustData: TrustData; darkBg?: boolean }) => {
  return (
    <div className="w-fit mx-auto font-helvetica flex justify-center items-center">
      <a target="_blank" href={trustData.url} className="w-full flex justify-center items-center">
        <span
          className={`text-sm lg:text-base font-medium ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } flex self-center mr-3.5`}
        >
          {trustData.label}
        </span>

        <span title={`${trustData.value} out of five star rating on Trustpilot`}>
          <FiveStarsIcon className="mr-1 w-[92px] md:w-[108px]" />
          <span className="sr-only">Five Star Review</span>
        </span>

        <div
          className={`hidden md:flex text-sm w-[114px] ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } tracking-normal mx-[7px] pt-0.5 items-baseline justify-between`}
        >
          <span className="font-medium">{trustData.count.toLocaleString()}</span>
          <span className="font-medium"> reviews on</span>
        </div>

        <span title="Trustpilot">
          {darkBg ? (
            <TPLogoWhite width="80" className="mx-auto hidden md:block" />
          ) : (
            <TPLogo width="80" className="mx-auto hidden md:block" />
          )}
          <span className="sr-only">Trustpilot Logo</span>
        </span>
      </a>
    </div>
  )
}

export default TPMicro
