import cx from 'classnames'
import { Fragment, useState } from 'react'
import { Menu, Transition, Dialog } from '@headlessui/react'
import {
  ShoppingCartIcon,
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  UserIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'
import popularTest from '../public/images/product/qfit_bowel_cancer_test_box_thumb.jpg'
import SelphLogo from '../public/images/logo.svg?svgr'
import SelphLogoWhite from '../public/images/logoWhite.svg?svgr'
import type { RoutesLink } from '../routes'
import { useUi } from '../components/ui/use-ui'
import TrustpilotBox from './Trustpilot/trustpilot-box'
import { ProductLink } from './product/product-link'
import { useBasket } from './checkout/use-basket'
import { useUser } from './user/use-user'
import * as UI from '@/ui'
import { ProductType } from '@/gql'

export type HeaderBgColours = 'blue' | 'white' | 'transparent'

type HeaderProps = {
  darkBg?: boolean
  bgColour?: HeaderBgColours
}

export const Header = ({ darkBg = false, bgColour = 'transparent' }: HeaderProps) => {
  const bgColours = {
    blue: 'bg-selphBlue-300',
    white: 'bg-white',
    transparent: 'bg-transparent',
  }

  const ui = useUi({
    styles: {
      color: { options: bgColours, selected: bgColour },
    },
    name: 'Header',
  })

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { numberOfLineItems, destroyBasket } = useBasket()
  const { isReady: isUserReady, isLoggedIn, logout, user } = useUser()
  return (
    <>
      {/* Mobile Menu */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-selphGrey-700 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-selphGrey-200">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex-shrink-0 flex items-center px-4">
                  <UI.Link to="root">
                    <SelphLogo width={100} height={32} />
                    <span className="sr-only">Home</span>
                  </UI.Link>
                </div>

                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <UI.Block gap="small" className="px-2">
                    {navigation.pages.map((page) => (
                      <UI.Block gap="small" key={page.name}>
                        <UI.Link
                          key={page.name}
                          to={page.to}
                          color="navbar"
                          className="flex items-center font-light"
                          onClick={() => setSidebarOpen(false)}
                        >
                          {({ active }) => (
                            <div
                              className={cx(
                                {
                                  'border border-l-hotPink-500 cursor-default': active,
                                  'border-l-transparent': !active,
                                },
                                'pl-2 border-0 border-l-4 w-full text-lg',
                              )}
                            >
                              <span className={cx({ 'link-hover-pink': !active })}>{page.name}</span>
                            </div>
                          )}
                        </UI.Link>
                      </UI.Block>
                    ))}
                    <UI.Block gap="none" className="pt-5">
                      <span className="block uppercase text-sm text-selphGrey-600">POPULAR TESTS</span>

                      <div className="max-w-xs mx-auto  overflow-hidden ">
                        <div className="relative w-full h-32">
                          <ProductLink
                            slug="q-fit-bowel-cancer-test"
                            type={ProductType.LabTest}
                            onClick={() => setSidebarOpen(false)}
                          >
                            <UI.Image
                              src={popularTest}
                              alt="Popular test"
                              fill
                              placeholder="blur"
                              sizes="320px"
                              className="object-center object-cover rounded-b-lg"
                            />
                          </ProductLink>
                        </div>

                        <div className="py-2 h-full">
                          <UI.Block gap="small">
                            <ProductLink
                              slug="qfit-bowel-cancer-test"
                              type={ProductType.LabTest}
                              onClick={() => setSidebarOpen(false)}
                            >
                              <span className="text-base font-semibold text-selphBlue-600">
                                qFIT Bowel Cancer Screening Test
                              </span>
                            </ProductLink>

                            <UI.Paragraph size="small" color="darkGrey">
                              Got gut symptoms or just want to screen for bowel cancer? The qFIT test - used in the NHS
                              and the Bowel Cancer Screening Programme - picks up blood in the stool which can be caused
                              by bowel cancer.
                            </UI.Paragraph>
                          </UI.Block>
                        </div>
                      </div>
                    </UI.Block>
                  </UI.Block>
                </div>
              </Dialog.Panel>
            </Transition.Child>

            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Main Menu */}
      <div className={`z-20 relative py-2 ${ui.className}`} id="navbar">
        <div className="flex flex-col gap-y-2 max-w-7xl mx-auto px-2 sm:px-4 md:px-6">
          <div className="relative flex flex-wrap justify-between gap-x-5 gap-y-2">
            <div className="flex gap-x-2">
              {/* Menu Icon */}
              <div className="flex items-center md:hidden z-10">
                <button
                  type="button"
                  className={cx(
                    darkBg ? 'text-white' : 'text-selphBlue-600',
                    'focus:outline-none focus:ring-2 focus:ring-hotPink-500 hover:ring-2 hover:ring-hotPink-500 md:hidden',
                  )}
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="relative stroke-1 h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Logo */}
              <div className="flex items-center justify-center md:items-stretch md:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <UI.Link to="root">
                    {darkBg ? (
                      <SelphLogoWhite className="w-16 md:w-20 lg:w-24" />
                    ) : (
                      <SelphLogo className="w-16 md:w-20 lg:w-24" />
                    )}
                    <span className="sr-only">Home</span>
                  </UI.Link>
                </div>
              </div>
            </div>

            {/* Trustbox */}
            <div className="flex items-center max-md:order-first max-md:w-full">
              <TrustpilotBox boxStyle="micro" darkBg={darkBg} />
            </div>

            <div className="flex gap-x-2 items-center justify-end min-w-36">
              {isUserReady && (
                <>
                  {/* Profile dropdown */}
                  {isLoggedIn ? (
                    <Menu as="div" className="relative z-20">
                      <Menu.Button className="rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-hotPink-500 hover:ring-2 hover:ring-hotPink-500">
                        <span className="sr-only">Open user menu</span>

                        <div
                          className={cx(darkBg ? 'text-white' : 'text-selphBlue-600', 'px-1 flex items-center py-1 ')}
                        >
                          {user?.friendlyName && (
                            <>
                              <span className="font-light truncate max-w-32 lg:max-w-72 max-[340px]:hidden  text-right">
                                {user.friendlyName.trim()}
                              </span>
                              <ChevronRightIcon className="h-4 w-4 shrink-0 max-[340px]:hidden" />
                            </>
                          )}
                          <UserIcon
                            className={cx(
                              darkBg || bgColour === 'blue'
                                ? 'fill-white opacity-90'
                                : 'stroke-hotPink-500 fill-hotPink-500 border-hotPink-500',
                              'h-6 w-6 lg:h-8 lg:w-8 stroke-1 rounded-full shrink-0 border p-0.5',
                            )}
                          />
                        </div>
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="overflow-hidden origin-top-right absolute z-20 right-0 mt-2 w-48 rounded-b-xl shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <UI.Link
                                  to={item.to}
                                  color="none"
                                  className={cx(
                                    active ? 'bg-selphGrey-50' : '',
                                    'block px-4 py-2 text-sm text-selphGrey-800',
                                  )}
                                >
                                  {item.name}
                                </UI.Link>
                              )}
                            </Menu.Item>
                          ))}

                          <hr />

                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={cx(
                                  active ? 'bg-selphGrey-50' : '',
                                  'block px-4 py-2 text-sm text-selphGrey-800',
                                )}
                                onClick={(e) => {
                                  e.preventDefault()
                                  logout()
                                  destroyBasket()
                                }}
                              >
                                Logout
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <UI.Link
                      color="none"
                      to="account"
                      className={cx(
                        darkBg ? 'text-white' : 'text-selphBlue-600',
                        'flex items-center pl-2 pr-1 py-1  rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-hotPink-500 hover:ring-2 hover:ring-hotPink-500',
                      )}
                    >
                      <span className="max-[250px]:hidden">Sign In</span>
                      <ChevronRightIcon className="h-4 w-4 shrink-0 max-[250px]:hidden" />
                      <UserCircleIcon className="h-6 w-6 lg:h-8 lg:w-8 stroke-1 rounded-full fill-transparent shrink-0 " />
                    </UI.Link>
                  )}
                </>
              )}
              {/* Basket */}
              <div className="relative p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-hotPink-500 hover:ring-2 hover:ring-hotPink-500 z-10">
                <UI.Link to="cart">
                  <ShoppingCartIcon
                    className={cx(darkBg ? 'text-white' : 'text-selphBlue-600', 'w-6 h-6 lg:h-8 lg:w-8 stroke-1')}
                    aria-hidden="true"
                  />

                  <div className="absolute -top-1 -right-1" title="Cart Items">
                    <div className="h-5 w-5 flex bg-hotPink-500 text-sm rounded-full text-selphGrey-50">
                      <span className="m-auto my-auto">{numberOfLineItems}</span>
                    </div>
                  </div>
                </UI.Link>
              </div>
            </div>
          </div>
          {/* Navigation */}
          <div className="hidden md:flex md:space-x-5 items-center justify-center lg:justify-around flex-wrap px-5 pb-1">
            {navigation.pages.map((item) => (
              <UI.Link
                key={item.name}
                to={item.to}
                color="navbar"
                className={cx(
                  darkBg ? 'text-white' : 'text-selphBlue-600',
                  'text-base lg:text-xl link-hover-pink whitespace-nowrap',
                )}
              >
                {item.name}
              </UI.Link>
            ))}
          </div>

          <UI.Breadcrumb size={{ default: 'sm', md: 'md' }} />
        </div>
      </div>
    </>
  )
}

export default Header

const userNavigation = [
  { name: 'My Dashboard', to: 'account' },
  { name: 'My Orders', to: 'accountOrders' },
  { name: 'My Results History ', to: 'accountResultsHistory' },
  { name: 'Account Settings', to: 'accountSettings' },
]

const navigation = {
  pages: [
    { name: 'All Tests', to: 'tests' },
    { name: 'Gut Health', to: 'gutHealthTests' },
    { name: "Men's Health", to: 'mensHealthTests' },
    { name: "Women's Health", to: 'womensHealthTests' },
    { name: 'Metabolic Health', to: 'metabolicHealthTests' },
    { name: 'General Health', to: 'generalHealthTests' },
    { name: 'How it works', to: ['learnCategory', { slug: 'testing' }] as RoutesLink },
    { name: 'Learn', to: 'learn' },
    { name: 'Help', to: 'help' },
  ],
}
