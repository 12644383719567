import { v4 as uuid } from 'uuid'
import { gql } from '@apollo/client'
import ReactGA from 'react-ga4'
import base64url from 'base64url'
import { isEqual } from 'lodash-es'
import { useReducer, useEffect, useState, createContext, useContext, Context, Reducer } from 'react'
import { useRouter } from 'next/router'
import { getParam } from '../../helpers/params'
import { useUser } from '../../components/user/use-user'
import {
  LineFragment,
  BasketLineModifiersFragmentDoc,
  TestProductFragmentDoc,
  useCheckoutCalculateOrderQuery,
  CheckoutCalculateOrderQuery,
} from '@/gql'

gql`
  query CheckoutCalculateOrder($input: CalculateOrderInput!) {
    calculateOrder(input: $input) {
      __typename
      lines {
        ...Line
      }
      total
      subTotal
      requiresShipping
      requiresAccountActivation
      discount {
        ...OrderDiscount
      }
    }
  }

  fragment OrderDiscount on BasketDiscount {
    __typename
    amount
    code
    description
    failureMessage
  }

  fragment Line on BasketLine {
    __typename
    product {
      ...Product
    }
    quantity
    totalPrice
    ...BasketLineModifiers
  }
  ${BasketLineModifiersFragmentDoc}

  fragment BasketLineModifiers on BasketLine {
    __typename
    modifiers {
      code
      name
      price
    }
  }

  fragment Product on Product {
    __typename
    sku
    name
    slug
    description
    active
    price
    type
    featuredImage {
      src
      name
    }
    ...TestProduct
  }
  ${TestProductFragmentDoc}
`

class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`)
  }
}

type BasketTotalType = {
  subTotal: number
  total: number
}

export type BasketContextType = {
  updateDiscountCode: ({ discountCode }: { discountCode: string }) => void

  addToBasket: ({
    sku,
    name,
    quantity,
    price,
    modifiers,
  }: {
    sku: string
    name: string
    quantity?: number
    price: number
    modifiers?: string[]
  }) => void

  removeFromBasket: ({
    sku,
    name,
    quantity,
    price,
    modifiers,
  }: {
    sku: string
    name: string
    quantity: number
    price: number
    modifiers?: string[]
  }) => void

  updateQuantity: ({ sku, quantity, modifiers }: { sku: string; quantity: number; modifiers?: string[] }) => void

  addModifier: ({
    sku,
    modifiers,
    modifierToAdd,
  }: {
    sku: string
    modifiers: string[] | []
    modifierToAdd: string[]
  }) => void

  setModifiersById: ({ id, modifiers }: { id: string; modifiers: string[] }) => void

  removeModifier: ({
    sku,
    modifiers,
    modifierToRemove,
  }: {
    sku: string
    modifiers: string[] | []
    modifierToRemove: string
  }) => void

  basketTotal: BasketTotalType
  basketItems: LineFragment[]

  // Combined view of 'raw' state (i.e. not necessarily calculated yet)
  // with associated 'calculated' item from backend.
  // 'item' and 'calculated' may be out of sync while 'isCalculating' is true
  combinedBasketItems: { item: BasketItem; calculated?: LineFragment }[]
  numberOfLineItems: number
  destroyBasket: () => void
  requiresShipping: boolean
  requiresAccountActivation: boolean
  isEmpty: () => boolean
  id: string
  isReady: boolean
  discount: CheckoutCalculateOrderQuery['calculateOrder']['discount']
  isCalculating: boolean
  basketError: boolean
}

export const BasketContext = createContext<BasketContextType | undefined>(undefined)

export interface BasketItem {
  id?: string
  sku: string
  quantity: number
  modifiers?: string[]
}

export interface BasketState {
  id?: string
  items: BasketItem[]
  discountCode?: string
}

function replaceAt<T>(array: Array<T>, index: number, value: T) {
  const ret = array.slice(0)
  ret[index] = value
  return ret
}

function findBasketItem(basket: BasketState, sku: string, modifiers?: string[]): [number, BasketItem] {
  const modsSet = new Set(modifiers)
  const existingIx = basket.items.findIndex((item) => item.sku === sku && isEqual(modsSet, new Set(item.modifiers)))
  const existingItem = basket.items[existingIx]

  return [existingIx, existingItem]
}

function findBasketItemById(basket: BasketState, id: string): [number, BasketItem] {
  const existingIx = basket.items.findIndex((item) => item.id === id)
  const existingItem = basket.items[existingIx]

  return [existingIx, existingItem]
}

function getSavedBasket(): BasketState | undefined {
  if (typeof window !== 'undefined') {
    const basket = window.localStorage.basketContents ? JSON.parse(window.localStorage.basketContents) : undefined

    if (basket && basket.items) {
      return {
        ...basket,
        items: basket.items.map((item: BasketItem) => ({
          ...item,
          id: item.id || uuid(),
        })),
      }
    }
  }

  return undefined
}

async function saveBasket(emailAddress: string | null, basket: BasketState) {
  if (typeof window !== 'undefined') {
    window.localStorage.basketContents = JSON.stringify(basket)
  }

  if (!basket.id) {
    return
  }

  const res = await fetch('/api/save-basket', {
    body: JSON.stringify({
      basket,
      email: emailAddress,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  if (!res.ok) {
    console.error(res)
    throw new Error('Error saving basket')
  }
}

const createEmptyBasket = () => ({
  items: [],
})

type BasketReducerAction =
  | {
      type: 'UPDATE_DISCOUNT_CODE'
      code?: string | null
    }
  | {
      type: 'ADD_ITEM'
      sku: string
      qty: number
      modifiers?: string[]
    }
  | {
      type: 'REMOVE_ITEM'
      sku: string
      modifiers?: string[]
    }
  | {
      type: 'UPDATE_ITEM_QUANTITY'
      sku: string
      qty: number
      modifiers?: string[]
    }
  | {
      type: 'ADD_MODIFIER'
      sku: string
      modifiers?: string[]
      modifierToAdd: string[]
    }
  | {
      type: 'SET_MODIFIERS_BY_ID'
      id: string
      modifiers: string[]
    }
  | {
      type: 'REMOVE_MODIFIER'
      sku: string
      modifiers?: string[]
      modifierToRemove: string
    }
  | {
      type: 'DESTROY_BASKET'
    }
  | {
      type: 'RESET_BASKET'
      basket: BasketState
      cid?: string | null
    }

const basketStateReducer: Reducer<BasketState, BasketReducerAction> = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DISCOUNT_CODE':
      return {
        ...state,
        discountCode: action.code || undefined,
      }

    case 'ADD_ITEM': {
      const [existingIx, existingItem] = findBasketItem(state, action.sku, action.modifiers)

      // TODO: Enforce max quantities

      const item = existingItem
        ? {
            ...existingItem,
            quantity: existingItem.quantity + action.qty,
          }
        : {
            id: uuid(),
            sku: action.sku,
            quantity: action.qty,
            modifiers: action.modifiers,
          }

      return {
        ...state,
        items: replaceAt(state.items, existingIx >= 0 ? existingIx : state.items.length, item),
      }
    }

    case 'REMOVE_ITEM': {
      const [existingIx] = findBasketItem(state, action.sku, action.modifiers)

      const items = [...state.items.slice(0, existingIx), ...state.items.slice(existingIx + 1)]

      return {
        ...state,
        items,
      }
    }

    case 'UPDATE_ITEM_QUANTITY': {
      const [existingIx, existingItem] = findBasketItem(state, action.sku, action.modifiers)

      if (action.qty === 0) {
        throw new Error('Setting qty to zero not yet supported')
      }

      return {
        ...state,
        items: replaceAt(state.items, existingIx, {
          ...existingItem,
          quantity: action.qty,
        }),
      }
    }

    case 'DESTROY_BASKET': {
      // Preserve ID so saved Mailchimp basket can be deleted
      return {
        ...createEmptyBasket(),
        id: state.id,
      }
    }

    case 'ADD_MODIFIER': {
      const [existingIx, existingItem] = findBasketItem(state, action.sku, action.modifiers)

      const newModifiers = new Set(existingItem.modifiers)
      action.modifierToAdd.forEach((mod) => newModifiers.add(mod))

      return {
        ...state,
        items: replaceAt(state.items, existingIx, {
          ...existingItem,
          modifiers: Array.from(newModifiers),
        }),
      }
    }

    case 'SET_MODIFIERS_BY_ID': {
      const [existingIx, existingItem] = findBasketItemById(state, action.id)
      const newModifiers = new Set(action.modifiers)

      if (existingItem) {
        return {
          ...state,
          items: replaceAt(state.items, existingIx, {
            ...existingItem,
            modifiers: Array.from(newModifiers),
          }),
        }
      }

      return state
    }

    case 'REMOVE_MODIFIER': {
      const [existingIx, existingItem] = findBasketItem(state, action.sku, action.modifiers)

      const newModifiers = new Set(existingItem.modifiers)
      newModifiers.delete(action.modifierToRemove)

      return {
        ...state,
        items: replaceAt(state.items, existingIx, {
          ...existingItem,
          modifiers: Array.from(newModifiers),
        }),
      }
    }

    case 'RESET_BASKET':
      return action.basket

    default:
      throw new UnreachableCaseError(action)
  }
}

// 'Wrapping' reducer to perform common post-modification actions
const basketReducer: Reducer<BasketState, BasketReducerAction> = (state, action) => {
  let newState = basketStateReducer(state, action)

  // Clear discount code once basket becomes empty
  if (newState.items.length === 0) {
    newState = {
      ...newState,
      discountCode: undefined,
    }
  }

  // Initialise ID if we don't already have one and there is some basket activity
  if (!newState.id && newState.items.length > 0) {
    newState = {
      ...newState,
      id: uuid(),
    }
  }

  return newState
}

// Reverse of function defined in api/save-basket.api.ts
function urlToBasket(param: string) {
  try {
    const [id, items, discountCode] = JSON.parse(base64url.decode(param))
    return {
      id,
      items: items.map((i: [string, number, string[]]) => {
        const [sku, quantity, modifiers] = i
        return { id: uuid(), sku, quantity, modifiers }
      }),
      discountCode,
    }
  } catch (e) {
    console.error('Failed to recover basket from URL param', e)
    return null
  }
}

export const BasketProvider = ({ children }: { children: React.ReactNode }) => {
  // 'Readiness' here distinguishes between server and client so we're not
  // trying to do basket stuff during server side render
  const [isReady, setIsReady] = useState(false)
  const [basket, dispatch] = useReducer(basketReducer, createEmptyBasket())
  const { user } = useUser()
  const { query: params, isReady: isRouterReady } = useRouter()

  useEffect(() => {
    const toRecover = getParam(params.recoverBasket)
    const recovered = toRecover && urlToBasket(toRecover)

    if (recovered) {
      // TODO (Alasdair): Decide what to do if we have a recover param
      // and a local storage basket. Maybe prompt the user?
      dispatch({ type: 'RESET_BASKET', basket: recovered })

      // TODO: update route to remove param
    } else {
      // Attempt to load a local storage basket.
      // We could do this in the initial state but then it causes a server
      // rendering mismatch
      const saved = getSavedBasket()

      if (saved) {
        dispatch({ type: 'RESET_BASKET', basket: saved })
      }
    }

    setIsReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouterReady])

  // Re-sync basket when user email address changes
  useEffect(() => {
    if (isReady) {
      saveBasket(user?.emailAddress || null, basket)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, saveBasket, user?.emailAddress])

  const { loading, error, data, previousData } = useCheckoutCalculateOrderQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        orderLines: basket.items.map(({ sku, quantity, modifiers }) => ({ sku, quantity, modifiers })),
        discountCode: basket.discountCode,
      },
    },
    skip: !isReady,
    onCompleted: () => {
      // TODO: If the calculated order changes any items,
      // e.g. removes an unrecognised item/modifier
      // or imposes a max quantity,
      // update the client-side basket with that new info
      saveBasket(user?.emailAddress || null, basket)
    },
  })

  if (error) {
    console.error('Error calculating basket:', error)
  }

  const calculated = loading ? previousData?.calculateOrder : data?.calculateOrder

  return (
    <BasketContext.Provider
      value={{
        updateDiscountCode: ({ discountCode }) => {
          dispatch({ type: 'UPDATE_DISCOUNT_CODE', code: discountCode })
        },

        addToBasket: ({ sku, name, quantity = 1, price, modifiers }) => {
          dispatch({ type: 'ADD_ITEM', sku, qty: quantity, modifiers })

          ReactGA.event('add_to_cart', {
            currency: 'GBP',
            value: price && price / 100,
            items: [{ item_id: sku, id: sku, item_name: name, price: price && price / 100, quantity: quantity }],
          })
        },

        removeFromBasket: ({ sku, name, quantity, price, modifiers }) => {
          dispatch({ type: 'REMOVE_ITEM', sku, modifiers })

          ReactGA.event('remove_from_cart', {
            currency: 'GBP',
            value: (price * quantity) / 100,
            items: [{ item_id: sku, id: sku, item_name: name, price: price / 100, quantity: quantity }],
          })
        },

        updateQuantity: ({ sku, quantity, modifiers }) => {
          dispatch({ type: 'UPDATE_ITEM_QUANTITY', sku, qty: quantity, modifiers })
        },

        addModifier: ({ sku, modifiers, modifierToAdd }) => {
          dispatch({ type: 'ADD_MODIFIER', sku, modifiers, modifierToAdd })
        },

        setModifiersById: ({ id, modifiers }) => {
          dispatch({ type: 'SET_MODIFIERS_BY_ID', id, modifiers })
        },

        removeModifier: ({ sku, modifiers, modifierToRemove }) => {
          dispatch({ type: 'REMOVE_MODIFIER', sku, modifiers, modifierToRemove })
        },

        basketTotal: {
          subTotal: calculated?.subTotal || 0,
          total: calculated?.total || 0,
        },

        basketItems: calculated?.lines || [],
        combinedBasketItems: basket.items.map((item, i) => ({
          item: item,
          calculated: calculated?.lines[i],
        })),

        numberOfLineItems: basket.items.length,

        destroyBasket: () => {
          dispatch({ type: 'DESTROY_BASKET' })
        },

        requiresShipping: calculated?.requiresShipping || false,
        requiresAccountActivation: calculated?.requiresAccountActivation || false,
        isEmpty: () => basket.items.length === 0,
        isReady,
        id: basket.id || '',
        discount: calculated?.discount,
        isCalculating: loading,
        basketError: !!error,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}

export const useBasket = () => useContext<BasketContextType>(BasketContext as Context<BasketContextType>)
