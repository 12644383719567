import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export type ModalProps = {
  children: React.ReactNode
  show: boolean
  onClose: () => void
  title?: string
  color?: 'light' | 'dark'
}

const bgColors = {
  light: 'bg-selphGrey-50',
  dark: 'bg-selphGrey-200',
}

export const Modal = ({ title, color, show, onClose, children }: ModalProps) => {
  const bgColor = bgColors[color || 'light']

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-20" open={show} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-selphGrey-600 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-visible rounded-lg ${bgColor} px-3 pt-2 sm:pt-4 pb-2 sm:pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-fit sm:p-4 sm:mx-2`}
              >
                <div className="absolute top-0 right-0 pt-2 pr-2 sm:pr-4 sm:pt-4">
                  <button
                    type="button"
                    className={`rounded-md ${bgColor} text-gray-400 hover:text-gray-500`}
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-0 sm:text-left">
                    {title && (
                      <Dialog.Title as="h3" className="mr-8 text-lg font-medium leading-6 text-selphGrey-800 mb-2">
                        {title}
                      </Dialog.Title>
                    )}
                    {children}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.displayName = 'Modal'

export default Modal
