import { useUi } from './use-ui'

export type ContainerStyles = 'contained' | 'none' | 'slim'

export type PageProps = {
  children: React.ReactNode
  containerStyle?: ContainerStyles
}

export const Page = ({ children, containerStyle = 'contained' }: PageProps) => {
  const containerStyles = {
    default: `relative grow `,
    contained: 'py-4 px-2 sm:px-4 md:px-6 mx-auto w-full max-w-7xl',
    slim: 'p-4 sm:p-8 mx-auto',
    none: '',
  }

  const { className, attributes } = useUi({
    styles: { page: { options: containerStyles, selected: containerStyle } },
    name: 'Page',
  })

  return (
    <div {...attributes} className={className} id="bodyContent">
      {children}
    </div>
  )
}

Page.displayName = 'Page'

export default Page
